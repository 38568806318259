<div class="navbar bg-base-200">
    @if (!currentPage?.startsWith('plans')) {
        <div class="flex-1">
            <a
                [tuiHint]="tip"
                tuiHintAppearance="onDark"
                class="btn btn-ghost text-xl normal-case"
                (click)="toggleSidebar()"
            >
                <i class="bx bx-menu text-2xl text-base-content"></i>
            </a>
            <ng-template #tip>
                <kbd class="kbd">M</kbd>
            </ng-template>
        </div>
    } @else {
        <div class="flex-1">
            <a [routerLink]="['/']" class="btn btn-ghost text-xl normal-case">
                <img
                    class="w-[175px]"
                    [src]="
                        themeService.isDark()
                            ? '/assets/images/cerp-logo-addon-dark.png'
                            : '/assets/images/cerp-logo-addon-light.png'
                    "
                />
            </a>
        </div>
    }

    <div class="flex-none">
        <ul class="hide-on-mobile menu menu-horizontal">
            <li>
                <span class="pt-2 opacity-60">
                    {{ date$ | async | date: 'EEEE, MMMM d, y h:mm a' }}
                </span>
            </li>
        </ul>

        <div>
            <div class="indicator">
                @if (chatService.totalUnread() > 0) {
                    <span class="badge indicator-item badge-secondary">{{
                        chatService.totalUnread()
                    }}</span>
                }
                <label
                    class="btn btn-circle btn-ghost text-xl normal-case"
                    (click)="toggleDrawer()"
                >
                    <i class="bx bx-chat"></i>
                </label>
            </div>
        </div>
        @if (isDrawerOpen) {
            <div
                class="w-screen-sm md:w-screen-md absolute right-16 top-full z-50 inline-flex rounded-md bg-base-100 shadow-lg"
            >
                <app-chat-list class="h-1/2 lg:w-52"></app-chat-list>
                <app-chat-content class="h-1/2 w-96">
                    <i class="bx bx-x cursor-pointer" (click)="closeDrawer()"></i>
                </app-chat-content>
            </div>
        }

        @if (journalStateService.select('showButton') | async) {
            <div class="dropdown dropdown-end">
                <label
                    (click)="showJournalEntryDialog(false)"
                    tabindex="0"
                    class="btn btn-ghost mr-1 bg-warning"
                >
                    <i class="bx bx-task"> </i>
                    Enter daily update
                </label>
            </div>
        }

        <div class="hide-on-mobile dropdown dropdown-end">
            <app-theme-switcher></app-theme-switcher>
        </div>

        <div class="hide-on-mobile dropdown dropdown-end">
            <label tabindex="0" class="btn btn-circle btn-ghost">
                <div class="indicator">
                    <i class="bx bx-bell text-2xl"></i>
                    <span class="badge indicator-item badge-error badge-sm"></span>
                </div>
            </label>
            <div
                tabindex="0"
                class="card dropdown-content card-compact z-50 mt-3 w-96 bg-base-100 shadow"
            >
                <div class="card-body">
                    @if (!isExtensionInstalled) {
                        <div class="">
                            <i class="bx bxs-circle pr-4 text-error"></i>
                            <a
                                class="text-info"
                                href="https://chrome.google.com/webstore/detail/{{ extensionId }}"
                                target="_blank"
                            >
                                Install the Chrome Extension to track your time.
                            </a>
                        </div>
                    }
                    <div class="max-h-80 overflow-auto">
                        @for (
                            item of notificationStateService.select('userLogs') | async;
                            track item
                        ) {
                            <div class="mr-2 px-2 py-2 hover:bg-base-200">
                                <p class="prose text-sm" [innerHTML]="item.logHtml"></p>
                                <p class="text-right text-xs text-base-content text-opacity-40">
                                    {{ item.createdAt | date: 'short' }}
                                </p>
                            </div>
                        }
                        <div class="text-center">
                            <button
                                tuiButton
                                type="button"
                                (click)="loadMoreNotifications()"
                                appearance="primary"
                                size="s"
                                class="w-40"
                            >
                                @if (!(notificationStateService.select('loading') | async)) {
                                    <span>Load More</span>
                                }
                                @if (notificationStateService.select('loading') | async) {
                                    <i class="bx bx-loader-alt animate-spin"></i>
                                }
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="dropdown dropdown-end">
            <label tabindex="0" class="btn btn-circle btn-ghost">
                <div class="indicator">
                    <i title="To Do" class="bx-list-plus bx text-2xl"></i>
                </div>
            </label>
            <div
                tabindex="0"
                class="card dropdown-content card-compact z-50 mt-3 w-96 bg-base-100 shadow"
            >
                <div class="card-body">
                    <app-todo-panel></app-todo-panel>
                </div>
            </div>
        </div>

        @if (authStateService.isOwner()) {
            <div class="dropdown dropdown-end ml-2 md:ml-6">
                <select
                    [formControl]="ownerRoleChangeControl"
                    *tuiLet="authStateService.select('user') | async as user"
                    class="select select-bordered w-28"
                >
                    <option value disabled>Act as...</option>
                    @for (roleItem of roles; track roleItem) {
                        <option
                            [selected]="roleItem.value === user.role"
                            [disabled]="roleItem.value === user.role"
                            [value]="roleItem.value"
                        >
                            {{ roleItem.label }}
                        </option>
                    }
                </select>
            </div>
        }

        <div
            *tuiLet="authStateService.select('user') | async as user"
            class="dropdown dropdown-end ml-2 md:ml-4"
        >
            <label tabindex="0" class="avatar btn btn-circle btn-ghost relative">
                <div class="w-10 rounded-full">
                    <img useFallbackImage [src]="user?.profileImage" />
                </div>
                @if (!user?.profileImage) {
                    <div
                        title="Please update your profile picture"
                        class="absolute right-0 top-0 h-3 w-3 rounded-full bg-warning"
                    ></div>
                }
            </label>
            <ul
                tabindex="0"
                class="menu dropdown-content menu-sm z-50 mt-3 w-52 rounded-box bg-base-100 p-2 shadow"
            >
                <li>
                    <a [routerLink]="dashboardRoutes.profile.path">
                        <i class="bx bx-user-circle mr-2"></i>
                        Profile
                        @if (!user?.profileImage) {
                            <div class="">
                                <i
                                    title="Please complete your profile"
                                    size="xs"
                                    class="bx bx-info-circle text-error"
                                ></i>
                            </div>
                        }
                    </a>
                </li>
                @if (
                    authStateService.isLowLevelUser() &&
                    !(appStateService.select('isGoogleConnected') | async)
                ) {
                    <li>
                        <a [routerLink]="dashboardRoutes.profile.path" class="text-purple-700">
                            <i class="bx bxl-google mr-2"></i>
                            Connect to Google
                        </a>
                    </li>
                }
                @if (authStateService.isOwner()) {
                    <li>
                        <a [routerLink]="'/' + planRoutes.current.path">
                            <i class="bx bx-credit-card mr-2"></i>
                            Subscription
                        </a>
                    </li>
                }
                @if (authStateService.isOwner()) {
                    <li>
                        <a [routerLink]="'/' + shopRoutes.index.path">
                            <i class="bx bx-cart-add mr-2"></i>
                            Cerp Shop
                        </a>
                    </li>
                }
                <li>
                    <a [href]="billingUrl" target="_blank">
                        <i class="bx bxs-bank mr-2"></i>
                        Account & Billing
                    </a>
                </li>
                @if (authStateService.isSuperAdmin()) {
                    <li>
                        <a [routerLink]="adminRoutes.index.path">
                            <i class="bx bxs-dashboard mr-2"></i>
                            Admin Dashboard
                        </a>
                    </li>
                }
                <li>
                    <a [routerLink]="dashboardRoutes.settings.path">
                        <i class="bx bx-cog mr-2"></i>
                        Preferences
                    </a>
                </li>
                <li>
                    <a (click)="logout()">
                        <i class="bx bx-exit mr-2"></i>
                        Logout
                    </a>
                </li>
            </ul>
        </div>
    </div>
</div>

import { CommonModule, DatePipe } from '@angular/common'
import {
    Component,
    ElementRef,
    Injector,
    OnInit,
    ViewChild,
    effect,
    inject,
    signal,
} from '@angular/core'
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms'
import { SuggestionsComponent } from '@core/components/suggestions/suggestions.component'
import { UseFallbackImageDirective } from '@core/directives/use-fallback-image.directive'
import { AlertService } from '@core/services/alert.service'
import { AppStateService } from '@core/states/app-state.service'
import { TaigaUiImports } from '@core/ui/taiga-ui-imports'
import { AuthStateService } from '@modules/auth/states/auth-state.service'
import { ChatRoom } from '@modules/chat/models/chat.model'
import { ChatNamePipe } from '@modules/chat/pipes/chat-name.pipe'
import { ChatService } from '@modules/chat/services/chat.service'
import { ChatHighlightStateService } from '@modules/chat/states/chat-highlight-state.service'
import { ContentApiService } from '@modules/content/services/content-api.service'
import { TuiDialogService } from '@taiga-ui/core'
import { PolymorpheusComponent } from '@tinkoff/ng-polymorpheus'
import { InfiniteScrollDirective } from 'ngx-infinite-scroll'
import { debounceTime, filter, map, take, tap, timer } from 'rxjs'
import { ChatImagePipe } from '../../pipes/chat-image.pipe'
import { AddChatDialogComponent } from '../add-chat-dialog/add-chat-dialog.component'

@Component({
    selector: 'app-chat-content',
    standalone: true,
    templateUrl: './chat-content.component.html',
    styleUrl: './chat-content.component.scss',
    imports: [
        DatePipe,
        CommonModule,
        FormsModule,
        ChatNamePipe,
        ChatImagePipe,
        SuggestionsComponent,
        ReactiveFormsModule,
        InfiniteScrollDirective,
        UseFallbackImageDirective,
        ...TaigaUiImports,
    ],
})
export class ChatContentComponent implements OnInit {
    chatService = inject(ChatService)
    authStateService = inject(AuthStateService)
    chatHighlightStateService = inject(ChatHighlightStateService)
    private alertService = inject(AlertService)
    private contentApiService = inject(ContentApiService)
    private appStateService = inject(AppStateService)
    private readonly dialogs = inject(TuiDialogService)
    private readonly injector = inject(Injector)

    @ViewChild('scrollToBottomContainer') private scrollContainer: ElementRef

    dropdownOpen = false
    loading = signal<boolean>(false)
    contentsName = signal<string[] | null>(null)
    newMessage = new FormControl('')

    constructor() {
        effect(() => {
            const page = this.chatService.page()
            const messages = this.chatService.currentChatRecentMessages()

            if (messages.length && page === 1) {
                timer(10)
                    .pipe(take(1))
                    .subscribe(() => this.scrollToBottom())
            }

            this.chatHighlightStateService.setContentIndices(messages)
            this.chatHighlightStateService.setTagContents(messages)
        })
    }

    ngOnInit() {
        this.watchTypingEvents()
        this.markAsRead()
        this.showSuggestions('@')
    }

    onSuggestionSelected(suggestion: string) {
        this.newMessage.setValue(`@${suggestion}@`, { emitEvent: false })
    }

    sendMessage() {
        if (this.newMessage.value.trim()) {
            this.chatService.sendMessage(this.newMessage.value)
            this.newMessage.setValue('')
        }
    }

    onScroll() {
        const page = this.chatService.page()
        const totalPages = this.chatService.totalPages()
        if (page < totalPages) this.chatService.page.update((page) => page + 1)
    }

    leaveChatGroup() {
        this.alertService
            .confirm('Confirm Leave', 'Are you sure you want to leave this chat?')
            .subscribe((result) => {
                if (result) {
                    this.chatService.leaveGroupChat()
                }
            })
    }

    openGroupChatEditModal() {
        const currentChat = this.chatService.currentChat()
        if (!currentChat.isGroupChat) return

        this.dialogs
            .open<ChatRoom>(new PolymorpheusComponent(AddChatDialogComponent, this.injector), {
                size: 'auto',
                data: this.chatService.currentChat(),
                dismissible: true,
                label: 'Edit Chat',
            })
            .subscribe({
                next: (result) => {
                    if (result) {
                        this.chatService.updateGroupChat(result)
                        this.chatService.switchRoom(result)
                    }
                },
            })
    }

    private scrollToBottom() {
        const { nativeElement } = this.scrollContainer
        nativeElement.scrollTop = nativeElement.scrollHeight
    }

    private markAsRead() {
        this.newMessage.valueChanges.pipe(debounceTime(300)).subscribe({
            next: () => {
                this.chatService.markAsReadFrontend()
            },
        })
    }

    private watchTypingEvents() {
        this.newMessage.valueChanges.pipe(map((value) => value.trim())).subscribe((value) => {
            if (value !== '') {
                this.chatService.startedTyping()
            } else {
                this.chatService.stoppedTyping()
            }
        })
    }

    private findContentsBySearchTerm(search: string) {
        this.loading.set(true)
        this.contentApiService
            .findByMainKeyword(this.appStateService.getState().organization?.id, search.slice(1))
            .pipe(take(1))
            .subscribe(({ data: contentNames }) => {
                this.loading.set(false)
                this.contentsName.set(contentNames)
                this.dropdownOpen = true
            })
    }

    private showSuggestions(startCharacter: string) {
        this.newMessage.valueChanges
            .pipe(
                debounceTime(800),
                tap(() => {
                    this.dropdownOpen = false
                }),
                filter((value) => value[0] === startCharacter && value.length > 1),
            )
            .subscribe((value) => {
                this.findContentsBySearchTerm(value)
            })
    }
}

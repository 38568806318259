<ul>
    @for (chatRoom of chatRooms; track chatRoom) {
        <li
            (click)="selectChat(chatRoom)"
            [class.bg-base-300]="chatService.currentChat()?.id === chatRoom?.id"
            class="relative flex cursor-pointer items-center justify-between p-2 hover:bg-base-300"
        >
            <div class="flex items-center">
                <img
                    class="mr-2 h-8 w-8 rounded-full"
                    useFallbackImage
                    [src]="chatRoom | appChatImage"
                    alt="User Avatar"
                />
                <div class="user-info">
                    <p class="font-bold">
                        {{ chatRoom | appChatName }}
                    </p>
                </div>

                @if (chatService.unreadMap()[chatRoom.id]; as unreadCount) {
                    <span
                        class="badge badge-error badge-lg absolute right-2 top-[50%] translate-y-[-50%] text-xs text-white transition"
                    >
                        {{ unreadCount }}
                    </span>
                }
            </div>

            <!-- Dropdown for each chat -->
            <div class="relative inline-block text-left">
                <!-- Dropdown Trigger -->
                <i
                    class="bx bx-dots-vertical-rounded cursor-pointer"
                    (click)="toggleDropdown(chatRoom.id); $event.stopPropagation()"
                ></i>

                <!-- Dropdown Menu -->
                @if (dropdownOpenStates[chatRoom.id]) {
                    <div
                        class="absolute right-0 z-50 mt-2 w-40 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5"
                    >
                        <div
                            class="py-1"
                            role="menu"
                            aria-orientation="vertical"
                            aria-labelledby="options-menu"
                        >
                            @if (chatRoom.isArchive) {
                                <p
                                    class="flex cursor-pointer items-center px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                                    role="menuitem"
                                    (click)="
                                        selectChatAction(chatRoom, menusMap.Unarchive);
                                        $event.stopPropagation()
                                    "
                                >
                                    <i class="bx bx-archive-out mr-2 text-lg"></i>
                                    Unarchive
                                </p>

                                @if (authStateService.isOwner() || !chatRoom.isGroupChat) {
                                    <p
                                        class="flex cursor-pointer items-center px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                                        role="menuitem"
                                        (click)="
                                            selectChatAction(chatRoom, menusMap.Delete);
                                            $event.stopPropagation()
                                        "
                                    >
                                        <i class="bx bx-trash mr-2 text-lg"></i>
                                        Delete
                                    </p>
                                }
                            } @else {
                                <p
                                    class="flex cursor-pointer items-center px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                                    role="menuitem"
                                    (click)="
                                        selectChatAction(chatRoom, menusMap.Archive);
                                        $event.stopPropagation()
                                    "
                                >
                                    <i class="bx bx-archive-in mr-2 text-lg"></i>
                                    Archive
                                </p>
                            }
                        </div>
                    </div>
                }
            </div>
        </li>
    }
</ul>

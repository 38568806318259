import { Pipe, type PipeTransform, inject } from '@angular/core'
import { AuthStateService } from '@modules/auth/states/auth-state.service'
import { ChatRoom } from '../models/chat.model'

@Pipe({
    name: 'appChatName',
    standalone: true,
})
export class ChatNamePipe implements PipeTransform {
    private authStateService = inject(AuthStateService)

    transform(value?: ChatRoom): string {
        if (!value) return ''

        if (value.isGroupChat) {
            return value.name
        }

        const participant = value.participants.find(
            (participant) => participant.id !== this.authStateService.getUserId(),
        )
        if (participant) {
            return `${participant?.firstName} ${participant?.lastName}`
        }
        return 'Deleted Account'
    }
}

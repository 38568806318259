<div class="h-[calc(100vh-120px)] overflow-auto rounded-bl-lg rounded-tl-lg bg-base-100 shadow-md">
    <div class="border-r border-base-200">
        <div class="flex justify-between bg-secondary p-2 text-base-100">
            <h2 class="text-lg font-bold">Chats</h2>
            <i (click)="openAddGroupChatModal()" class="bx bx-plus cursor-pointer"></i>
        </div>
        <div class="h-full">
            <!-- Active Chats -->
            @for (item of ['groupChatRooms', 'privateChatRooms']; track $index) {
                @let chatRooms =
                    item === 'groupChatRooms'
                        ? (chatService.groupChatRooms$ | async)
                        : (chatService.privateChatRooms$ | async);

                @if (chatRooms.length) {
                    <div class="divider divider-start">
                        <span class="pl-4">{{
                            item === 'groupChatRooms' ? 'Group Chat' : 'Private Chat'
                        }}</span>
                    </div>
                }

                <app-chatroom [chatRooms]="chatRooms"></app-chatroom>
            }

            <button
                appearance="flat"
                type="button"
                tuiButton
                size="s"
                (click)="loadMyArchivedChats()"
                class="mx-auto my-3 !block cursor-pointer"
            >
                Archive chats
            </button>

            <!-- Archived Chats -->
            @if (showArchivedChats()) {
                @for (item of ['groupChatRooms', 'privateChatRooms']; track $index) {
                    @let archivedChatRooms =
                        item === 'groupChatRooms'
                            ? (chatService.archivedGroupChatRooms$ | async)
                            : (chatService.archivedPrivateChatRooms$ | async);

                    @if (archivedChatRooms.length) {
                        <div class="divider divider-start">
                            <span class="pl-4">{{
                                item === 'groupChatRooms' ? 'Group Chat' : 'Private Chat'
                            }}</span>
                        </div>
                    }

                    <app-chatroom [chatRooms]="archivedChatRooms"></app-chatroom>
                }
            }
        </div>
    </div>
</div>

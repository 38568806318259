import { HttpClient } from '@angular/common/http'
import { Injectable, inject } from '@angular/core'
import { ApiResponse } from '@core/models/api-response.model'
import { environment } from '@environment/environment'
import { Observable } from 'rxjs'
import { ChatMessage, ChatRoom, ChatUnreadResponse } from '../models/chat.model'

@Injectable({
    providedIn: 'root',
})
export class ChatApiService {
    protected http = inject(HttpClient)

    private endpoint = `${environment.BASE_URL}/v2/chats`

    createGroupChat(name: string, participantIds: string[]): Observable<ApiResponse<ChatRoom>> {
        return this.http.post<ApiResponse<ChatRoom>>(`${this.endpoint}/group`, {
            name,
            participantIds,
        })
    }

    updateGroupChat(
        id: string,
        name: string,
        participantIds: string[],
    ): Observable<ApiResponse<ChatRoom>> {
        return this.http.patch<ApiResponse<ChatRoom>>(`${this.endpoint}/group/${id}`, {
            name,
            participantIds,
        })
    }

    deleteChat(id: string, chatType: string): Observable<ApiResponse<ChatRoom>> {
        return this.http.delete<ApiResponse<ChatRoom>>(`${this.endpoint}/${chatType}/${id}`)
    }

    getGroupChat(id: string): Observable<ApiResponse<ChatRoom>> {
        return this.http.get<ApiResponse<ChatRoom>>(`${this.endpoint}/group/${id}`)
    }

    leaveGroupChat(id: string): Observable<ApiResponse<ChatRoom>> {
        return this.http.delete<ApiResponse<ChatRoom>>(`${this.endpoint}/group/${id}/leave`)
    }

    addParticipant(groupId: string, participantId: string): Observable<ApiResponse<ChatRoom>> {
        return this.http.post<ApiResponse<ChatRoom>>(
            `${this.endpoint}/group/${groupId}/participants/${participantId}`,
            {},
        )
    }

    removeParticipant(groupId: string, participantId: string): Observable<ApiResponse<ChatRoom>> {
        return this.http.delete<ApiResponse<ChatRoom>>(
            `${this.endpoint}/group/${groupId}/participants/${participantId}`,
        )
    }

    getMyChats(): Observable<ApiResponse<ChatRoom[]>> {
        return this.http.get<ApiResponse<ChatRoom[]>>(`${this.endpoint}/my-chats`)
    }

    getMyArchivedChats(): Observable<ApiResponse<ChatRoom[]>> {
        return this.http.get<ApiResponse<ChatRoom[]>>(`${this.endpoint}/my-chats/archived`)
    }

    getMyChatsUnreadCount(): Observable<ApiResponse<ChatUnreadResponse[]>> {
        return this.http.get<ApiResponse<ChatUnreadResponse[]>>(
            `${this.endpoint}/my-chats/unread-count`,
        )
    }

    markAsRead(chatId: string): Observable<ApiResponse<ChatRoom>> {
        return this.http.post<ApiResponse<ChatRoom>>(`${this.endpoint}/${chatId}/mark-as-read`, {})
    }

    createOrGetPrivateChat(receiverUserId: string): Observable<ApiResponse<ChatRoom>> {
        return this.http.post<ApiResponse<ChatRoom>>(
            `${this.endpoint}/private/${receiverUserId}`,
            {},
        )
    }

    archiveChat(id: string, chatType: string): Observable<ApiResponse<ChatRoom>> {
        return this.http.patch<ApiResponse<ChatRoom>>(
            `${this.endpoint}/${chatType}/archive/${id}`,
            {},
        )
    }

    unarchiveChat(id: string, chatType: string): Observable<ApiResponse<ChatRoom>> {
        return this.http.patch<ApiResponse<ChatRoom>>(
            `${this.endpoint}/${chatType}/unarchive/${id}`,
            {},
        )
    }

    toggleArchiveChat(
        id: string,
        isGroupChat: boolean,
        isArchive: boolean,
    ): Observable<ApiResponse<ChatRoom>> {
        const url = `${this.endpoint}/toggle-archive/${id}`
        return this.http.patch<ApiResponse<ChatRoom>>(url, { isArchive, isGroupChat })
    }

    getOrganizationChats(): Observable<ApiResponse<ChatRoom[]>> {
        return this.http.get<ApiResponse<ChatRoom[]>>(`${this.endpoint}`)
    }

    getMessages(
        chatId: string,
        page: number,
        size: number,
    ): Observable<ApiResponse<ChatMessage[]>> {
        const params = { page, size, orderBy: 'desc', sortBy: 'createdAt' }
        return this.http.get<ApiResponse<ChatMessage[]>>(`${this.endpoint}/${chatId}/messages`, {
            params,
        })
    }

    sendMessage(chatId: string, message: string): Observable<ApiResponse<ChatMessage>> {
        return this.http.post<ApiResponse<ChatMessage>>(`${this.endpoint}/${chatId}/messages`, {
            message,
        })
    }
}
